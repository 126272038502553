@font-face {
  font-family: 'CeraPRO-Black';
  src: url("../fonts/CeraPRO-Black.otf") format("opentype"); }

@font-face {
  font-family: 'CeraPRO-Bold';
  src: url("../fonts/CeraPRO-Bold.otf") format("opentype"); }

@font-face {
  font-family: 'CeraPRO-Medium';
  src: url("../fonts/CeraPRO-Medium.otf") format("opentype"); }

@font-face {
  font-family: 'CeraPRO-Regular';
  src: url("../fonts/CeraPRO-Regular.otf") format("opentype"); }

*,
*::before,
*::after {
  box-sizing: inherit; }

.clearfix::after {
  display: table;
  clear: both;
  content: ''; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

h1 {
  margin: 0.67em 0;
  font-size: 2em; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  vertical-align: baseline;
  line-height: 0;
  font-size: 75%; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  margin: 0 2px;
  border: 1px solid silver;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: 700; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.outer-wrapper {
  width: 100%; }

.inner-wrapper {
  margin: 0 auto;
  max-width: 1200px; }

.inner-padding {
  padding: 50px 80px; }

.inner-padding-bottom-0 {
  padding: 50px 80px 0 80px; }

.padding-bottom {
  padding: 0 0 50px 0; }

.gutter-right {
  padding: 0 20px 0 0; }

.gutter-left {
  padding: 0 0 0 20px; }

.inner-heading-padding {
  padding: 10px 20px; }

.inner-sm-padding {
  padding: 25px 80px; }

.hide-visually {
  position: absolute;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(100%); }

.center-items {
  display: flex;
  justify-content: center;
  padding-bottom: 30px; }

@media (max-width: 779px) and (min-width: 480px) {
  .inner-padding {
    padding: 20px 40px; }
  .inner-padding-bottom-0 {
    padding: 20px 40px 0 40px; }
  .inner-heading-padding {
    padding: 10px; }
  .inner-sm-padding {
    padding: 20px 40px; } }

@media (max-width: 479px) {
  .inner-padding {
    padding: 20px; }
  .inner-padding-bottom-0 {
    padding: 20px 20px 0 20px; }
  .inner-heading-padding {
    padding: 10px; }
  .inner-sm-padding {
    padding: 20px; } }

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  background-color: #ecf8fa;
  background-repeat: no-repeat;
  background-size: 100% auto; }

h1,
h2,
h3,
h4 {
  margin: 36px 0 24px 0;
  color: #129dc0;
  font-family: "CeraPRO-Bold", sans-serif; }

h2 {
  font-size: 50px; }

h3 {
  color: #174060;
  font-size: 30px; }

h4 {
  margin: 24px 0 6px;
  line-height: 1.4;
  color: #6b8599;
  font-family: "CeraPRO-Bold", sans-serif;
  font-size: 18px; }

p {
  margin: 24px 0 12px;
  line-height: 1.4;
  color: #6b8599;
  font-family: "CeraPRO-Medium", sans-serif;
  font-size: 18px; }
  p strong {
    font-family: "CeraPRO-Bold", sans-serif; }
  p a {
    color: #6b8599; }

a {
  transition: 0.3s;
  color: #6b8599; }
  a:hover, a:focus {
    transition: 0.3s;
    color: #129dc0; }

ol,
ul {
  margin: 24px 0;
  padding-left: 20px;
  line-height: 1.4;
  color: #6b8599;
  font-family: "CeraPRO-Medium", sans-serif;
  font-size: 18px; }
  ol li,
  ul li {
    margin: 2px 0; }

ol {
  list-style: none;
  counter-reset: num; }
  ol > li {
    counter-increment: num; }
    ol > li::before {
      display: inline-block;
      margin-left: -1em;
      width: 1em;
      color: #174060;
      font-family: "CeraPRO-Medium", sans-serif;
      content: counter(num) ". "; }
  ol ul {
    margin-top: 0;
    padding-left: 20px;
    list-style-type: disc; }

address {
  font-style: normal; }

@media (max-width: 779px) {
  h2 {
    font-size: 30px; }
  h3 {
    color: #174060;
    font-size: 24px; }
  h4 {
    font-size: 16px; }
  p {
    font-size: 16px;
    line-height: 1.35; }
  ol,
  ul {
    font-size: 16px; } }

.h3-underline-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 30px; }

.h3-underline {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  text-align: center;
  line-height: 1.4; }
  .h3-underline::after {
    display: block;
    position: absolute;
    left: 15%;
    background: #174060;
    width: 70%;
    height: 4px;
    content: ''; }

.h3-underline-left {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  text-align: left;
  line-height: 1.4; }
  .h3-underline-left::after {
    display: block;
    position: absolute;
    left: 0%;
    background: #174060;
    width: 50%;
    height: 4px;
    content: ''; }

.center-align {
  text-align: center; }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.p-blue {
  color: #174060; }

.p-teal {
  color: #129dc0; }

.p-em {
  margin: 0 0 12px 0;
  font-family: "CeraPRO-Regular", sans-serif; }

.p-top-margin {
  margin-top: 60px; }

.p-margin-0 {
  margin: 0; }

.p-margin-12 {
  margin: 12px 0; }

.p-800 {
  margin-right: auto;
  margin-left: auto;
  max-width: 800px; }

.p-disclaimer {
  margin-top: 100px;
  font-size: 16px; }

.h2-fz-40 {
  font-size: 40px; }

.teal-btn {
  transition: 0.3s;
  margin: 10px 0;
  outline: 0;
  border: 0;
  border-radius: 4px;
  background: #129dc0;
  padding: 10px 40px;
  text-transform: uppercase;
  color: #fff;
  font-family: "CeraPRO-Medium", sans-serif;
  font-size: 18px; }
  .teal-btn:focus, .teal-btn:hover {
    transition: 0.3s;
    background: #0f89a8; }

.ul-dash {
  list-style: none;
  margin: 2px 0; }
  .ul-dash > li::before {
    display: inline-block;
    margin-left: -1em;
    width: 1em;
    color: #6b8599;
    font-family: "CeraPRO-Medium", sans-serif;
    content: '- '; }

.grey-border {
  border: 1px solid #cecece; }

@media (max-width: 779px) {
  .p-disclaimer {
    margin-top: 50px;
    font-size: 14px; }
  .h2-fz-40 {
    font-size: 30px; } }

#main {
  opacity: 0; }

body:not(.post-din) .dscp-nav,
body:not(.post-din) .dscp-header-mobile-controls .menu-button,
body:not(.post-din) .prep-legal {
  display: none; }

.din-bg {
  background-image: url("../img/din-gradient-bg.jpg"); }
  .din-bg h2 {
    margin-bottom: 6px; }
  .din-bg h3 {
    margin: 6px 0 36px 0;
    font-family: "CeraPRO-Regular", sans-serif;
    font-size: 24px; }

.post-din {
  background-image: url("../img/body-bg.png"); }

.din-modal {
  margin: 20px 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 50px; }
  .din-modal-inner {
    margin: 0 auto;
    max-width: 800px; }
  .din-modal label {
    margin: 10px 0;
    line-height: 1.4;
    color: #6b8599;
    font-family: "CeraPRO-Medium", sans-serif;
    font-size: 18px; }
  .din-modal input {
    transition: 0.3s;
    margin: 10px 0;
    border: 2px solid #d4d4d4;
    box-shadow: inset 0 0 3px #d4d4d4;
    background: #fff;
    padding: 10px;
    width: 100%;
    color: #000;
    font-family: "CeraPRO-Medium", sans-serif;
    font-size: 18px; }
    .din-modal input:hover, .din-modal input:focus {
      transition: 0.3s;
      outline: 0;
      border: 2px solid #129dc0; }
  .din-modal .open-din-info {
    transition: 0.3s;
    margin: 12px 0 0 0;
    outline: 0;
    border: 0;
    background: transparent;
    text-align: center;
    text-decoration: underline;
    color: #6b8599;
    font-family: "CeraPRO-Medium", sans-serif; }
    .din-modal .open-din-info:hover, .din-modal .open-din-info:focus {
      transition: 0.3s;
      color: #174060; }

.qr-banner {
  margin: 36px 0;
  border-radius: 20px;
  background: #3c0e36;
  padding: 20px; }
  .qr-banner-inner {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 700px; }
  .qr-banner-text {
    width: calc( 100% - 120px); }
  .qr-banner-img-wrapper {
    position: relative;
    width: 120px;
    height: 60px; }
    .qr-banner-img-wrapper img {
      display: block;
      position: absolute;
      top: -50px;
      width: 125%;
      height: auto; }
  .qr-banner p {
    margin: 12px 0;
    line-height: 1.2;
    color: #fff; }

body.modal-open {
  position: relative;
  overflow: hidden; }

.modal-1,
.modal-2 {
  display: none; }
  .modal-1.overlay.visible,
  .modal-2.overlay.visible {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh; }
  .modal-1 .din-info-modal,
  .modal-2 .din-info-modal {
    border-radius: 10px;
    background: #fff;
    padding: 30px 80px; }
    .modal-1 .din-info-modal-inner,
    .modal-2 .din-info-modal-inner {
      display: flex;
      align-items: flex-start;
      margin: 0 auto;
      max-width: 600px; }
      .modal-1 .din-info-modal-inner img,
      .modal-2 .din-info-modal-inner img {
        display: flex;
        width: 300px;
        height: auto; }
      .modal-1 .din-info-modal-inner p,
      .modal-2 .din-info-modal-inner p {
        margin-top: 70px;
        margin-left: -145px; }

@media (max-width: 779px) {
  .din-modal label {
    font-size: 16px; }
  .qr-banner-img-wrapper {
    position: relative;
    width: 120px; } }

@media (max-width: 479px) {
  .din-modal {
    padding: 30px 20px; }
  .modal-1 .din-info-modal,
  .modal-2 .din-info-modal {
    padding: 30px 20px; }
    .modal-1 .din-info-modal-inner img,
    .modal-2 .din-info-modal-inner img {
      width: 200px; }
    .modal-1 .din-info-modal-inner p,
    .modal-2 .din-info-modal-inner p {
      margin-top: 50px;
      margin-left: -100px; } }

.dscp-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.dscp-header-logo {
  width: 175px;
  height: auto; }

.dscp-nav ul {
  display: flex;
  list-style: none; }

.dscp-nav li {
  display: flex;
  align-items: center;
  justify-content: center; }
  .dscp-nav li:last-child a {
    border-right: 0; }

.dscp-nav a {
  display: block;
  transition: 0.3s;
  border-right: 2px solid #129dc0;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  color: #129dc0;
  font-family: "CeraPRO-Bold", sans-serif;
  font-size: 16px; }
  .dscp-nav a:hover, .dscp-nav a:focus {
    transition: 0.3s;
    color: #174060; }

.dscp-fr-button {
  display: inline-block;
  position: relative;
  transition: 0.3s;
  padding: 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: #6b8599; }
  .dscp-fr-button::after {
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #87d3e1;
    width: 16px;
    height: 16px;
    content: ''; }
  .dscp-fr-button:hover, .dscp-fr-button:focus {
    transition: 0.3s;
    color: #174060; }

.menu-button {
  display: none;
  width: 60px;
  height: 60px; }

@media (max-width: 779px) and (min-width: 480px) {
  .dscp-header {
    height: 100px; }
    .dscp-header-logo {
      width: 140px; } }

@media (max-width: 779px) {
  .menu-button {
    display: block;
    margin: 0;
    outline: 0;
    border: 0;
    background: transparent;
    padding: 5px; }
    .menu-button svg {
      display: block;
      width: 50px;
      height: 50px; }
    .menu-button .top-bar-teal,
    .menu-button .bottom-bar-teal,
    .menu-button .middle-bar-teal,
    .menu-button .middle-bar-teal-2 {
      transition: 0.5s;
      transform-origin: center; }
    .menu-button .top-bar-teal {
      transform: rotate(0deg) translate(0, 0); }
    .menu-button .middle-bar-teal {
      transform: rotate(0deg) translate(0, 0); }
    .menu-button .bottom-bar-teal {
      transform: rotate(0deg) translate(0, 0); }
    .menu-button.open .top-bar-teal,
    .menu-button.open .bottom-bar-teal {
      fill: transparent; }
    .menu-button.open .middle-bar-teal {
      transform: rotate(45deg); }
    .menu-button.open .middle-bar-teal-2 {
      transform: rotate(-45deg); }
    .menu-button.open .middle-bar-pink-2 {
      transform: rotate(-45deg) translate(2px, 0); }
    .menu-button.open .middle-bar-black-1 {
      transform: rotate(45deg) translate(1px, -4px); }
    .menu-button.open .middle-bar-black-2 {
      transform: rotate(-45deg) translate(2px, 1px); }
  .dscp-header {
    position: relative; }
    .dscp-header-mobile-controls {
      display: flex; }
  .dscp-nav {
    display: none;
    position: absolute;
    top: 100px;
    right: 0;
    left: 0;
    background: rgba(236, 248, 250, 0.95);
    width: 100%; }
    .dscp-nav.open {
      display: block; }
    .dscp-nav ul {
      flex-wrap: wrap;
      margin: 0;
      padding: 20px; }
    .dscp-nav li {
      width: 100%; }
    .dscp-nav a {
      border-right: 0; } }

@media (max-width: 479px) {
  .dscp-header {
    position: relative;
    height: 90px; }
    .dscp-header-logo {
      width: 120px; }
  .dscp-nav {
    top: 90px; } }

.dscp-intro video {
  display: block;
  width: 100%;
  height: auto; }

.dscp-getting-started {
  background: url("../img/man-bg.jpg");
  background-image: linear-gradient(135deg, rgba(231, 246, 249, 0.4), rgba(231, 246, 249, 0.4) 12%, rgba(231, 246, 249, 0.2) 12%, rgba(231, 246, 249, 0.2) 17%, transparent 17%, transparent 87%, rgba(231, 246, 249, 0.2) 87%, rgba(231, 246, 249, 0.2)), url("../img/man-bg.jpg");
  background-position: 50% 50%;
  background-size: cover; }

.dscp-diamond {
  display: flex;
  padding: 80px 0;
  overflow: hidden; }
  .dscp-diamond-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.33%; }
  .dscp-diamond-shape {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    transition: 0.6s;
    background: #129dc0;
    width: 200px;
    height: 200px; }
    .dscp-diamond-shape::before {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transition: 0.6s;
      z-index: -1;
      background: rgba(18, 157, 192, 0.7);
      width: 200px;
      height: 200px;
      content: ''; }
    .dscp-diamond-shape::after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.6s;
      z-index: -1;
      background: rgba(18, 157, 192, 0.7);
      width: 200px;
      height: 200px;
      content: ''; }
    .dscp-diamond-shape:hover::before {
      top: -10px;
      right: 10px;
      transition: 0.6s; }
    .dscp-diamond-shape:hover::after {
      top: 10px;
      left: 10px;
      transition: 0.6s; }
  .dscp-diamond-shape-fancy {
    position: relative;
    z-index: 1; }
    .dscp-diamond-shape-fancy::before {
      display: block;
      position: absolute;
      top: -10px;
      right: 10px;
      z-index: -1;
      background: rgba(18, 157, 192, 0.7);
      width: 200px;
      height: 200px;
      content: ''; }
    .dscp-diamond-shape-fancy::after {
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: -1;
      background: rgba(18, 157, 192, 0.7);
      width: 200px;
      height: 200px;
      content: ''; }
  .dscp-diamond-shape-inner {
    transform: rotate(-45deg);
    text-align: center; }
  .dscp-diamond a {
    z-index: 2;
    text-decoration: none;
    color: #fff;
    font-family: "CeraPRO-Bold", sans-serif;
    font-size: 20px; }
    .dscp-diamond a.sm {
      line-height: 1.2;
      font-size: 16px; }
    .dscp-diamond a::before {
      position: absolute;
      top: -80px;
      right: -14px;
      transform: rotate(45deg);
      background: transparent;
      cursor: pointer;
      width: 200px;
      height: 200px;
      content: ''; }

@media (max-width: 1023px) and (min-width: 780px) {
  .dscp-diamond-shape {
    width: 140px;
    height: 140px; }
    .dscp-diamond-shape::before {
      width: 140px;
      height: 140px; }
    .dscp-diamond-shape::after {
      width: 140px;
      height: 140px; }
  .dscp-diamond-shape-fancy::before {
    width: 140px;
    height: 140px; }
  .dscp-diamond-shape-fancy::after {
    width: 140px;
    height: 140px; }
  .dscp-diamond a {
    font-size: 14px; }
    .dscp-diamond a.sm {
      line-height: 1;
      font-size: 11px; }
    .dscp-diamond a::before {
      top: -28px;
      right: -2px; } }

@media (max-width: 779px) {
  .dscp-diamond {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0; }
    .dscp-diamond-link {
      padding: 60px 0;
      width: 100%; }
    .dscp-diamond a::before {
      top: -70px;
      right: 9px; } }

.dscp-about-intro .inner-wrapper {
  display: flex; }

.dscp-about-intro-text {
  padding-bottom: 50px;
  width: 66%; }

.dscp-about-intro-img {
  display: flex;
  align-items: flex-end;
  width: 34%; }
  .dscp-about-intro-img img {
    display: block;
    width: 100%;
    height: auto; }

.dscp-about-links ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.dscp-about-links a {
  transition: 0.5s;
  text-decoration: none;
  color: #174060;
  font-family: "CeraPRO-Regular", sans-serif; }
  .dscp-about-links a:hover {
    transition: 0.5s;
    color: #1885a0; }

.dscp-about-bg {
  background: #ecf8fa; }

.test-gradient {
  background: linear-gradient(45deg, rgba(211, 220, 226, 0.4), rgba(211, 220, 226, 0.4) 80px, rgba(211, 220, 226, 0.2) 80px, rgba(211, 220, 226, 0.2) 160px, transparent 160px, transparent), linear-gradient(135deg, rgba(211, 220, 226, 0.4), rgba(211, 220, 226, 0.4) 80px, rgba(211, 220, 226, 0.2) 80px, rgba(211, 220, 226, 0.2) 160px, transparent 160px, transparent), linear-gradient(225deg, rgba(211, 220, 226, 0.4), rgba(211, 220, 226, 0.4) 80px, rgba(211, 220, 226, 0.2) 80px, rgba(211, 220, 226, 0.2) 160px, transparent 160px, transparent), linear-gradient(315deg, rgba(211, 220, 226, 0.4), rgba(211, 220, 226, 0.4) 80px, rgba(211, 220, 226, 0.2) 80px, rgba(211, 220, 226, 0.2) 160px, #ecf8fa 160px, #ecf8fa), #ecf8fa; }

.dscp-about-gradient-bg {
  background: linear-gradient(135deg, rgba(211, 220, 226, 0.4), rgba(211, 220, 226, 0.4) 80px, rgba(211, 220, 226, 0.2) 80px, rgba(211, 220, 226, 0.2) 160px, transparent 160px, transparent), linear-gradient(315deg, rgba(211, 220, 226, 0.4), rgba(211, 220, 226, 0.4) 50px, rgba(211, 220, 226, 0.2) 50px, rgba(211, 220, 226, 0.2) 130px, #ecf8fa 130px, #ecf8fa), #ecf8fa; }

.dscp-about-gradient-bg-2 {
  background: linear-gradient(45deg, rgba(211, 220, 226, 0.2), rgba(211, 220, 226, 0.2) 120px, transparent 120px, transparent), linear-gradient(135deg, rgba(211, 220, 226, 0.4), rgba(211, 220, 226, 0.4) 140px, rgba(211, 220, 226, 0.2) 140px, rgba(211, 220, 226, 0.2) 220px, transparent 220px, transparent), linear-gradient(225deg, rgba(211, 220, 226, 0.2), rgba(211, 220, 226, 0.2) 180px, transparent 180px, transparent) #ecf8fa; }

.dscp-about-gradient-bg-3 {
  background: linear-gradient(135deg, rgba(211, 220, 226, 0.2), rgba(211, 220, 226, 0.2) 160px, transparent 160px, transparent), linear-gradient(315deg, rgba(211, 220, 226, 0.4), rgba(211, 220, 226, 0.4) 80px, rgba(211, 220, 226, 0.2) 80px, rgba(211, 220, 226, 0.2) 160px, #ecf8fa 160px, #ecf8fa), #ecf8fa; }

.dscp-row {
  display: flex;
  flex-wrap: wrap; }
  .dscp-row img {
    display: block;
    width: 100%;
    height: auto; }
  .dscp-row .quarter {
    width: 25%; }
  .dscp-row .three-quarter {
    width: 75%; }
  .dscp-row .third {
    width: 33%; }
  .dscp-row .two-thirds {
    width: 64%; }
  .dscp-row-text {
    padding-bottom: 50px;
    width: 66%; }
  .dscp-row-img {
    display: flex;
    align-items: flex-end;
    width: 34%; }
    .dscp-row-img img {
      display: block;
      width: 100%;
      height: auto; }

.dscp-icons img {
  margin: 0 auto;
  width: 50%; }

.dscp-callout {
  margin: 0 auto;
  border: 2px solid #129dc0;
  border-radius: 10px;
  background-image: linear-gradient(to right, #fff 85%, #ecf8fa 85%);
  padding: 0 20px;
  max-width: 600px; }
  .dscp-callout table {
    width: 100%; }
    .dscp-callout table tr {
      border-bottom: 2px solid #129dc0; }
      .dscp-callout table tr:last-child {
        border-bottom: 0; }
    .dscp-callout table td {
      border-right: 1px solid #129dc0;
      padding-right: 10px;
      width: calc( 100% - 160px); }
      .dscp-callout table td:last-child {
        border-right: 0;
        background: #ecf8fa;
        padding-left: 20px;
        width: 160px; }

.dscp-brochure-link {
  display: inline-block;
  transition: 0.3s;
  margin: 60px 0 20px 0;
  border-radius: 6px;
  background: #3c0e36;
  padding: 20px 50px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-family: "CeraPRO-Bold", sans-serif;
  font-size: 20px; }
  .dscp-brochure-link:hover, .dscp-brochure-link:focus {
    transition: 0.3s;
    background: #65185b;
    color: #fff; }

.dscp-back-to-top {
  display: block;
  position: sticky;
  bottom: 20px;
  left: calc( 50% - 50px);
  background: rgba(255, 255, 255, 0.7);
  width: 100px;
  height: 55px; }
  .dscp-back-to-top a {
    display: block;
    position: relative;
    padding-top: 30px;
    text-align: center;
    text-decoration: none;
    color: #6b8599;
    font-family: "CeraPRO-Bold", sans-serif; }
    .dscp-back-to-top a::before {
      display: block;
      position: absolute;
      top: 5px;
      left: calc( 50% - 20px);
      transition: 0.3s;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #174060;
      border-left: 20px solid transparent;
      content: ''; }
    .dscp-back-to-top a:hover::before, .dscp-back-to-top a:focus::before {
      transition: 0.3s;
      border-bottom: 20px solid #129dc0; }
  .dscp-back-to-top-fr {
    left: calc( 50% - 110px);
    width: 220px; }

@media (max-width: 479px) {
  .dscp-about-intro .inner-wrapper {
    flex-wrap: wrap; }
  .dscp-about-intro-text {
    width: 100%; }
  .dscp-about-intro-img {
    width: 100%; }
  .dscp-row {
    flex-wrap: wrap; }
    .dscp-row-text {
      width: 100%; }
    .dscp-row-img {
      width: 100%; }
    .dscp-row .quarter {
      width: 100%; }
    .dscp-row .three-quarter {
      width: 100%; }
    .dscp-row .third {
      width: 100%; }
    .dscp-row .two-thirds {
      width: 100%; }
  .dscp-icons {
    flex-wrap: wrap; }
    .dscp-icons .quarter {
      width: 100%; } }

.dscp-brochure-link-sm {
  display: block;
  margin: 20px 0;
  text-align: center; }

.dscp-brochure-sm {
  font-size: 16px; }

.brochure-text {
  width: calc( 100% - 260px); }

.brochure-img {
  padding-top: 80px;
  width: 260px; }

@media (max-width: 779px) {
  .brochure-text {
    width: 100%; }
    .brochure-text.gutter-right {
      padding-right: 0; }
  .brochure-img {
    padding: 20px 40px;
    width: 100%; }
    .brochure-img img {
      margin: 0 auto;
      max-width: 320px; }
  .dscp-brochure-link-sm {
    margin: 20px auto;
    padding: 20px;
    max-width: 320px; } }

.dscp-max-img {
  margin-top: 36px; }

.phone {
  text-decoration: none;
  font-weight: bold;
  color: inherit; }

.phone:hover,
.phone:focus {
  text-decoration: underline; }

@media (max-width: 479px) {
  .dscp-max-img {
    margin-top: 10px; }
  .dscp-patient-support-header .gutter-left {
    padding: 0; }
  .dscp-patient-support-header .gutter-right {
    padding: 0; } }

.dscp-legal h1,
.dscp-legal h2,
.dscp-legal h3 {
  margin: 48px 0 6px 0;
  color: #174060; }

.dscp-legal p {
  margin: 12px 0;
  line-height: 1.5; }

.dscp-legal strong {
  color: #174060; }

.dscp-footer {
  background: #174060;
  color: #fff; }
  .dscp-footer-upper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .dscp-footer-legal-wrapper {
    display: flex;
    justify-content: center; }
  .dscp-footer-legal {
    display: flex;
    align-items: stretch;
    margin: 0;
    padding: 10px 0;
    list-style: none;
    text-align: center;
    font-size: 16px; }
    .dscp-footer-legal li {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #fff; }
      .dscp-footer-legal li:first-child a {
        padding-left: 10px; }
      .dscp-footer-legal li:last-child {
        border-right: 0; }
    .dscp-footer-legal a {
      display: block;
      transform: 0.3s;
      padding: 2px 10px;
      text-decoration: none;
      color: #fff;
      font-family: "CeraPRO-Regular", sans-serif; }
      .dscp-footer-legal a:hover, .dscp-footer-legal a:focus {
        transform: 0.3s;
        color: #b4e1e8; }
  .dscp-footer-logos {
    display: flex;
    align-items: center;
    padding: 10px 0;
    flex: 1;
    justify-content: space-evenly; }
    .dscp-footer-logos img {
      display: block;
      margin: 10px;
      width: auto;
      height: 20px; }
    .dscp-footer-logos .logo-xsm {
      height: 15px; }
    .dscp-footer-logos .logo-sm {
      height: 20px; }
    .dscp-footer-logos .logo-md {
      height: 35px; }
    .dscp-footer-logos .logo-lg {
      height: 65px; }
  .dscp-footer-info {
    margin: 0 auto;
    max-width: 600px;
    text-align: center; }
    .dscp-footer-info p {
      line-height: 1.4;
      color: #fff;
      font-size: 12px; }

@media (max-width: 1023px) {
  .dscp-footer-upper {
    flex-wrap: wrap; }
  .dscp-footer-legal-wrapper {
    display: flex;
    justify-content: center;
    width: 100%; }
  .dscp-footer-logos {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%; } }
