.dscp-header {

	&-inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-logo {
		width: 175px;
		height: auto;
	}

}

.dscp-nav {

	ul {
		display: flex;
		list-style: none;
	}

	li {
		display: flex;
		align-items: center;
		justify-content: center;

		&:last-child {

			a {
				border-right: 0;
			}
		}

	}

	a {
		display: block;
		transition: 0.3s;
		border-right: 2px solid $teal;
		padding: 10px 20px;
		text-align: center;
		text-decoration: none;
		color: $teal;
		font-family: $cera-bold;
		font-size: 16px;

		&:hover,
		&:focus {
			transition: 0.3s;
			color: $navy;
		}
	}
}

.dscp-fr-button {
	display: inline-block;
	position: relative;
	transition: 0.3s;
	padding: 20px;
	text-transform: uppercase;
	text-decoration: none;
	color: $blue-grey;

	&::after {
		display: block;
		position: absolute;
		top: 20px;
		right: 0;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		border-left: 12px solid $light-blue;
		width: 16px;
		height: 16px;
		content: '';
	}

	&:hover,
	&:focus {
		transition: 0.3s;
		color: $navy;
	}
}

.menu-button {
	display: none;
	width: 60px;
	height: 60px;
}

//tablet
@media(max-width: 779px) and (min-width: 480px) {

	.dscp-header {
		height: 100px;

		&-logo {
			width: 140px;
		}

	}
}
@media(max-width: 779px) {

	.menu-button {
		display: block;
		margin: 0;
		outline: 0;
		border: 0;
		background: transparent;
		padding: 5px;

		svg {
			display: block;
			width: 50px;
			height: 50px;
		}

		.top-bar-teal,
		.bottom-bar-teal,
		.middle-bar-teal,
		.middle-bar-teal-2 {
			transition: 0.5s;
			transform-origin: center;
		}

		.top-bar-teal {
			transform: rotate( 0deg ) translate( 0, 0 );
		}

		.middle-bar-teal {
			transform: rotate( 0deg ) translate( 0, 0 );
		}

		.bottom-bar-teal {
			transform: rotate( 0deg ) translate( 0, 0 );
		}

		&.open {

			.top-bar-teal,
			.bottom-bar-teal {
				fill: transparent;
			}

			.middle-bar-teal {
				transform: rotate( 45deg );
			}

			.middle-bar-teal-2 {
				transform: rotate( -45deg );
			}

			.middle-bar-pink-2 {
				transform: rotate( -45deg ) translate( 2px, 0 );
			}

			.middle-bar-black-1 {
				transform: rotate( 45deg ) translate( 1px, -4px );
			}

			.middle-bar-black-2 {
				transform: rotate( -45deg ) translate( 2px, 1px );
			}
		}
	}

	.dscp-header {
		position: relative;

		&-mobile-controls {
			display: flex;
		}

	}

	.dscp-nav {
		display: none;
		position: absolute;
		top: 100px;
		right: 0;
		left: 0;
		background: $pale-blue-95;
		width: 100%;

		&.open {
			display: block;
    	}

		ul {
			flex-wrap: wrap;
			margin: 0;
			padding: 20px;
		}

		li {
			width: 100%;
		}

		a {
			border-right: 0;
		}

	}
}
//mobile
@media(max-width: 479px) {

	.dscp-header {
		position: relative;
		height: 90px;

		&-logo {
			width: 120px;
		}

	}

	.dscp-nav {
		top: 90px;
	}

}
