// *:focus {
// 	outline: 2px solid $teal;
// 	border-radius: 2px;
// 	box-shadow: 0 0 10px $pale-blue;
// }

html {
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
    background-color: $pale-blue;
	background-repeat: no-repeat;
	background-size: 100% auto;
}

h1,
h2,
h3,
h4 {
	margin: 36px 0 24px 0;
	color: $teal;
	font-family: $cera-bold;
}

h2 {
	font-size: 50px;
}

h3 {
	color: $navy;
	font-size: 30px;
}

h4 {
	margin: 24px 0 6px;
	line-height: 1.4;
	color: $blue-grey;
	font-family: $cera-bold;
	font-size: 18px;
}

p {
	margin: 24px 0 12px;
	line-height: 1.4;
	color: $blue-grey;
	font-family: $cera-med;
	font-size: 18px;

	strong {
		font-family: $cera-bold;
	}

	a {
		color: $blue-grey;
	}
}

a {
	transition: 0.3s;
	color: $blue-grey;

	&:hover,
	&:focus {
		transition: 0.3s;
		color: $teal;
	}
}

ol,
ul {
	margin: 24px 0;
	padding-left: 20px;
	line-height: 1.4;
	color: $blue-grey;
	font-family: $cera-med;
	font-size: 18px;

	li {
		margin: 2px 0;
	}
}

ol {
	list-style: none;
	counter-reset: num;

	> li {
		counter-increment: num;

		&::before {
			display: inline-block;
			margin-left: -1em;
			width: 1em;
			color: $navy;
			font-family: $cera-med;
			content: counter( num )'. ';
		}
	}

	ul {
		margin-top: 0;
		padding-left: 20px;
		list-style-type: disc;
	}
}

address {
	font-style: normal;
}

@media (max-width: 779px) {

	h2 {
		font-size: 30px;
	}

	h3 {
		color: $navy;
		font-size: 24px;
	}

	h4 {
		font-size: 16px;
	}

	p {
		font-size: 16px;
		line-height: 1.35;
	}

	ol,
	ul {
		font-size: 16px;
	}
}
