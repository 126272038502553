.dscp-intro {

	video {
		display: block;
		width: 100%;
		height: auto;
	}

}

.dscp-getting-started {
	background: url('../img/man-bg.jpg');
	background-image:  linear-gradient(
		135deg,
		$gradient-blue-40,
		$gradient-blue-40 12%,
		$gradient-blue-20 12%,
		$gradient-blue-20 17%,
		transparent 17%,
		transparent 87%,
		$gradient-blue-20 87%,
		$gradient-blue-20
		), url('../img/man-bg.jpg');
	background-position: 50% 50%;
	background-size: cover;
}

.dscp-diamond {
	display: flex;
	padding: 80px 0;
	overflow: hidden;

	&-link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 33.33%;
	}

	&-shape {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		transform: rotate( 45deg );
		transition: 0.6s;
		background: $teal;
		width: 200px;
		height: 200px;

		&::before {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			transition: 0.6s;
			z-index: -1;
			background: $teal-70;
			width: 200px;
			height: 200px;
			content: '';
		}

		&::after {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			transition: 0.6s;
			z-index: -1;
			background: $teal-70;
			width: 200px;
			height: 200px;
			content: '';
		}

		&:hover {

			&::before {
				top: -10px;
				right: 10px;
				transition: 0.6s;
			}

			&::after {
				top: 10px;
				left: 10px;
				transition: 0.6s;
			}
		}
	}

	&-shape-fancy {
		position: relative;
		z-index: 1;

		&::before {
			display: block;
			position: absolute;
			top: -10px;
			right: 10px;
			z-index: -1;
			background: $teal-70;
			width: 200px;
			height: 200px;
			content: '';
		}

		&::after {
			display: block;
			position: absolute;
			top: 10px;
			left: 10px;
			z-index: -1;
			background: $teal-70;
			width: 200px;
			height: 200px;
			content: '';
		}
	}

	&-shape-inner {
		transform: rotate( -45deg );
		text-align: center;
	}

	a {
		z-index: 2;
		text-decoration: none;
		color: $white;
		font-family: $cera-bold;
		font-size: 20px;

		&.sm {
			line-height: 1.2;
			font-size: 16px;
		}

		&::before {
			position: absolute;
			top: -80px;
			right: -14px;
			transform: rotate( 45deg );
			background: transparent;
			cursor: pointer;
			width: 200px;
			height: 200px;
			content: '';
		}
	}
}

@media (max-width: 1023px) and (min-width: 780px) {

	.dscp-diamond {

		&-shape {
			width: 140px;
			height: 140px;

			&::before {
				width: 140px;
				height: 140px;
			}

			&::after {
				width: 140px;
				height: 140px;
			}
		}

		&-shape-fancy {

			&::before {
				width: 140px;
				height: 140px;
			}

			&::after {
				width: 140px;
				height: 140px;
			}
		}

		a {
			font-size: 14px;

			&.sm {
				line-height: 1;
				font-size: 11px;
			}

			&::before {
				top: -28px;
				right: -2px;
			}
		}
	}
}

@media (max-width: 779px) {

	.dscp-diamond {
		display: flex;
		flex-wrap: wrap;
		padding: 20px 0;

		&-link {
			padding: 60px 0;
			width: 100%;
		}

		a {
			&::before {
				top: -70px;
				right: 9px;
			}
		}
	}
}
