.h3-underline-wrapper {
	display: flex;
	justify-content: center;
	padding-bottom: 30px;
}

.h3-underline {
	display: inline-block;
	position: relative;
	margin: 0 auto;
	text-align: center;
	line-height: 1.4;

	&::after {
		display: block;
		position: absolute;
		left: 15%;
		background: $navy;
		width: 70%;
		height: 4px;
		content: '';
	}

}

.h3-underline-left {
	display: inline-block;
	position: relative;
	margin: 0 auto;
	text-align: left;
	line-height: 1.4;

	&::after {
		display: block;
		position: absolute;
		left: 0%;
		background: $navy;
		width: 50%;
		height: 4px;
		content: '';
	}

}

.center-align {
	text-align: center;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.p-blue {
	color: $navy;
}

.p-teal {
	color: $teal;
}

.p-em {
	margin: 0 0 12px 0;
	font-family: $cera-reg;
}

.p-top-margin {
	margin-top: 60px;
}

.p-margin-0 {
	margin: 0;
}

.p-margin-12 {
	margin: 12px 0;
}

.p-800 {
	margin-right: auto;
	margin-left: auto;
	max-width: 800px;
}

.p-disclaimer {
	margin-top: 100px;
	font-size: 16px;
}

.h2-fz-40 {
	font-size: 40px;
}

.teal-btn {
	transition: 0.3s;
	margin: 10px 0;
	outline: 0;
	border: 0;
	border-radius: 4px;
	background: $teal;
	padding: 10px 40px;
	text-transform: uppercase;
	color: $white;
	font-family: $cera-med;
	font-size: 18px;

	&:focus,
	&:hover {
		transition: 0.3s;
		background: $teal-hover;
	}
}

.ul-dash {
	list-style: none;
	margin: 2px 0;
	> li {
		&::before {
			display: inline-block;
			margin-left: -1em;
			width: 1em;
			color: $blue-grey;
			font-family: $cera-med;
			content: '- ';
		}
	}
}

.grey-border {
	border: 1px solid #cecece;
}

@media (max-width: 779px) {

	.p-disclaimer {
		margin-top: 50px;
		font-size: 14px;
	}

	.h2-fz-40 {
		font-size: 30px;
	}
}
