.dscp-brochure-link-sm {
	display: block;
	margin: 20px 0;
	text-align: center;
}

// .dscp-brochure-md {

// }

.dscp-brochure-sm {
	font-size: 16px;
}

.brochure-text {
	width: calc( 100% - 260px );
}

.brochure-img {
	padding-top: 80px;
	width: 260px;
}

@media (max-width: 779px) {

	.brochure-text {
		width: 100%;

		&.gutter-right {
			padding-right: 0;
		}
	}

	.brochure-img {
		padding: 20px 40px;
		width: 100%;

		img {
			margin: 0 auto;
			max-width: 320px;
		}
	}

	.dscp-brochure-link-sm {
		margin: 20px auto;
		padding: 20px;
		max-width: 320px;
	}
}
