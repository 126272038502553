.dscp-about-intro {

	.inner-wrapper {
		display: flex;
	}

	&-text {
		padding-bottom: 50px;
		width: 66%;
	}

	&-img {
		display: flex;
		align-items: flex-end;
		width: 34%;

		img {
			display: block;
			width: 100%;
			height: auto;
		}

	}

}

.dscp-about-links {

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	a {
		transition: 0.5s;
		text-decoration: none;
		color: $navy;
		font-family: $cera-reg;

		&:hover {
			transition: 0.5s;
			color: $navy-hover;
		}
	}
}

.dscp-about-bg {
	background: $pale-blue;
}

//base all gradients off this - pixel stop doesnt stretch the way % stop does
.test-gradient {
	background: linear-gradient(
		//bottom left
		45deg,
		$gradient-grey-1,
		$gradient-grey-1 80px,
		$gradient-grey-2 80px,
		$gradient-grey-2 160px,
		transparent 160px,
		transparent
		), linear-gradient(
		//top left
		135deg,
		$gradient-grey-1,
		$gradient-grey-1 80px,
		$gradient-grey-2 80px,
		$gradient-grey-2 160px,
		transparent 160px,
		transparent
		), linear-gradient(
		//top right
		225deg,
		$gradient-grey-1,
		$gradient-grey-1 80px,
		$gradient-grey-2 80px,
		$gradient-grey-2 160px,
		transparent 160px,
		transparent
		), linear-gradient(
		//bottom right
		315deg,
		$gradient-grey-1,
		$gradient-grey-1 80px,
		$gradient-grey-2 80px,
		$gradient-grey-2 160px,
		$pale-blue 160px,
		$pale-blue
	), $pale-blue;
}

.dscp-about-gradient-bg {
	background: linear-gradient(
		//top left
		135deg,
		$gradient-grey-1,
		$gradient-grey-1 80px,
		$gradient-grey-2 80px,
		$gradient-grey-2 160px,
		transparent 160px,
		transparent
		), linear-gradient(
		//bottom right
		315deg,
		$gradient-grey-1,
		$gradient-grey-1 50px,
		$gradient-grey-2 50px,
		$gradient-grey-2 130px,
		$pale-blue 130px,
		$pale-blue
	), $pale-blue;
}

.dscp-about-gradient-bg-2 {
	background: linear-gradient(
		//bottom left
		45deg,
		$gradient-grey-2,
		$gradient-grey-2 120px,
		transparent 120px,
		transparent
		), linear-gradient(
		//top left
		135deg,
		$gradient-grey-1,
		$gradient-grey-1 140px,
		$gradient-grey-2 140px,
		$gradient-grey-2 220px,
		transparent 220px,
		transparent
		), linear-gradient(
		//top right
		225deg,
		$gradient-grey-2,
		$gradient-grey-2 180px,
		transparent 180px,
		transparent
	) $pale-blue;
}

.dscp-about-gradient-bg-3 {
	background: linear-gradient(
		//top left
		135deg,
		$gradient-grey-2,
		$gradient-grey-2 160px,
		transparent 160px,
		transparent
		), linear-gradient(
		//bottom right
		315deg,
		$gradient-grey-1,
		$gradient-grey-1 80px,
		$gradient-grey-2 80px,
		$gradient-grey-2 160px,
		$pale-blue 160px,
		$pale-blue
	), $pale-blue;
}

.dscp-row {
	display: flex;
	flex-wrap: wrap;

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	.quarter {
		width: 25%;
	}

	.three-quarter {
		width: 75%;
	}

	.third {
		width: 33%;
	}

	.two-thirds {
		width: 64%;
	}

	&-text {
		padding-bottom: 50px;
		width: 66%;
	}

	&-img {
		display: flex;
		align-items: flex-end;
		width: 34%;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

.dscp-icons {

	img {
		margin: 0 auto;
		width: 50%;
	}
}

.dscp-callout {
	margin: 0 auto;
	border: 2px solid $teal;
	border-radius: 10px;
	background-image:
	linear-gradient(
		to right,
		$white 85%,
		$pale-blue 85%
	);
	padding: 0 20px;
	max-width: 600px;

	table {
		width: 100%;

		tr {
			border-bottom: 2px solid $teal;

			&:last-child {
				border-bottom: 0;
			}
		}

		td {
			border-right: 1px solid $teal;
			padding-right: 10px;
			width: calc( 100% - 160px );

			&:last-child {
				border-right: 0;
				background: $pale-blue;
				padding-left: 20px;
				width: 160px;
			}
		}
	}
}

.dscp-brochure-link {
	display: inline-block;
	transition: 0.3s;
	margin: 60px 0 20px 0;
	border-radius: 6px;
	background: $purple;
	padding: 20px 50px;
	text-align: center;
	text-decoration: none;
	color: $white;
	font-family: $cera-bold;
	font-size: 20px;

	&:hover,
	&:focus {
		transition: 0.3s;
		background: $purple-hover;
		color: $white;
	}
}

.dscp-back-to-top {
	display: block;
	position: sticky;
	bottom: 20px;
	left: calc( 50% - 50px );
	background: $white-70;
	width: 100px;
	height: 55px;

	a {
		display: block;
		position: relative;
		padding-top: 30px;
		text-align: center;
		text-decoration: none;
		color: $blue-grey;
		font-family: $cera-bold;

		&::before {
			display: block;
			position: absolute;
			top: 5px;
			left: calc( 50% - 20px );
			transition: 0.3s;
			border-right: 20px solid transparent;
			border-bottom: 20px solid $navy;
			border-left: 20px solid transparent;
			content: '';
		}

		&:hover,
		&:focus {

			&::before {
				transition: 0.3s;
				border-bottom: 20px solid $teal;
			}
		}
	}

	&-fr {
		left: calc( 50% - 110px );
		width: 220px;
	}
}

@media (max-width: 479px) {

	.dscp-about-intro {

		.inner-wrapper {
			flex-wrap: wrap;
		}

		&-text {
			width: 100%;
		}

		&-img {
			width: 100%;
		}
	}

	.dscp-row {
		flex-wrap: wrap;

		&-text {
			width: 100%;
		}

		&-img {
			width: 100%;
		}

		.quarter {
			width: 100%;
		}

		.three-quarter {
			width: 100%;
		}

		.third {
			width: 100%;
		}

		.two-thirds {
			width: 100%;
		}
	}

	.dscp-icons {
		flex-wrap: wrap;

		.quarter {
			width: 100%;
		}
	}
}
