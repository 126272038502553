.dscp-max-img {
	margin-top: 36px;
}

.phone {
    text-decoration: none;
    font-weight: bold;
    color: inherit;
}

.phone:hover,
.phone:focus {
    text-decoration: underline;
}

@media (max-width: 479px) {

	.dscp-max-img {
		margin-top: 10px;
	}

	.dscp-patient-support-header {

		.gutter-left {
			padding: 0;
		}

		.gutter-right {
			padding: 0;
		}
	}
}
